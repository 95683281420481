<template>
  <div  class="px-15px px-lg-25px">
    <section class="py-5">
      <div class="container">
        <div class="d-flex align-items-start">
          <div class="aiz-user-panel">
            <div class="row gutters-10">
              <div class="col-md-4 mx-auto mb-3" >
                <div class="bg-grad-1 text-white rounded-lg overflow-hidden">
                  <span class="size-30px rounded-circle mx-auto bg-soft-primary d-flex align-items-center justify-content-center mt-3">
                    <i class="las la-dollar-sign la-2x text-white"></i>
                  </span>
                  <div class="px-3 pt-3 pb-3">
                    <div class="h4 fw-700 text-center">{{userMoney}}</div>
                    <div class="opacity-50 text-center">{{ $t('hui-yuan-yu-e') }}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mx-auto mb-3" >
                <a >
                  <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition">
                    <span class="size-60px rounded-circle mx-auto bg-secondary d-flex align-items-center justify-content-center mb-3">
                      <i class="las la-dharmachakra la-3x text-white"></i>
                    </span>
                    <div class="fs-18 text-primary">{{ $t('zhi-fu-she-zhi') }}</div>
                  </div>
                </a>
              </div>
                <div class="col-md-4 mx-auto mb-3" >
                  <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition" >
                    <span class="size-60px rounded-circle mx-auto bg-secondary d-flex align-items-center justify-content-center mb-3">
                      <i class="las la-plus la-3x text-white"></i>
                    </span>
                    <div class="fs-18 text-primary">{{ $t('ti-kuan-qing-qiu') }}</div>
                  </div>
                </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="form-box-content p-3">
                    <div class="form-group">
                      <textarea id="referral_code_url" class="form-control" readonly type="text" v-model="shareLink"></textarea>
                    </div>
                    <button  class="btn btn-primary float-right copy-icon" @click="copyData" >{{ $t('fu-zhi-di-zhi') }}</button>
                  </div>
                </div>
              </div>
            </div>
            <br>

            <div class="card">
              <form class="" id="sort_blogs" action="" method="GET">
                <div class="card-header row">
                  <div class="col text-center text-md-left">
                    <h5 class="mb-md-0 h6">{{ $t('hui-yuan-tong-ji') }}</h5>
                  </div>
                  <div class="col-md-5 col-xl-4">
                    <div class="input-group mb-0">
                      <a-select class="form-control clear" >
                        <a-select-option v-for="(item, i) in optionList" :key="i" :value="item.value">{{item.label}}</a-select-option>
                      </a-select>
                      <button class="btn btn-primary input-group-append" type="submit">{{ $t('shai-xuan') }}</button>
                    </div>
                  </div>
                </div>
              </form>

              <div class="card-body">
                <div class="row gutters-10">
                  <div class="col-md-3 mx-auto mb-3">
                    <a >
                      <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition">
                        <span class="size-60px mx-auto d-flex align-items-center justify-content-center mb-3">
                          <span class="la-3x text-white" style="color: #007bff !important;">
                            {{statisticsData.TotalSeller}}
                          </span>
                        </span>
                        <div class="fs-18 text-primary">{{ $t('zong-mai-jia') }}</div>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-3 mx-auto mb-3" >
                    <a>
                      <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition">
                        <span class="size-60px mx-auto d-flex align-items-center justify-content-center mb-3">
                          <span class="la-3x text-white" style="color: #007bff !important;">
                           {{statisticsData.TotalOrder}}
                          </span>
                        </span>
                        <div class="fs-18 text-primary">{{ $t('zong-ding-dan') }}</div>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-3 mx-auto mb-3" >
                    <a >
                      <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition">
                        <span class="size-60px mx-auto d-flex align-items-center justify-content-center mb-3">
                          <span class="la-3x text-white" style="color: #007bff !important;">
                            {{statisticsData.TotalAmount}}
                          </span>
                        </span>
                        <div class="fs-18 text-primary">{{ $t('zong-jin-e') }}</div>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-3 mx-auto mb-3" >
                    <a>
                      <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition">
                        <span class="size-60px mx-auto d-flex align-items-center justify-content-center mb-3">
                          <span class="la-3x text-white" style="color: #007bff !important;">
                            {{statisticsData.TotalCommission}}
                          </span>
                        </span>
                        <div class="fs-18 text-primary">{{ $t('zong-yong-jin') }}</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import pageBar from '@/components/page'
import { resetPrice } from '@/utils/common'
import { userInfo, wallet } from '@/api/user'
import { sellerStatistics } from '@/api/seller'
import Clipboard from 'clipboard';
export default {
  components: {
    pageBar
  },
  data() {
    return {
      optionList: [
        {
          value: '',
          label: this.$t('quan-bu')
        },
        {
          value: '1',
          label: this.$t('jin-tian')
        },
        {
          value: '2',
          label: this.$t('zui-jin-7-tian')
        },
        {
          value: '3',
          label: this.$t('zui-jin-30-tian')
        },
      ],
      tableData: [],
      page: {
        page: 1,
        total: 1,
        limit: 10
      },
      userMoney: '0.00',
      statisticsData: {
        TotalSeller: 0,
        TotalOrder: 0,
        TotalAmount: 0,
        TotalCommission: 0,
      }
    }
  },
  computed: {
    shareLink() {
      return this.promoteUrl + '/#/register?code=' + this.shareCode
    },
    promoteUrl() {
      return this.$store.state.promoteUrl
    },
    shareCode() {
      return this.$store.state.shareCode
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    resetPrice,
    init() {
      userInfo().then(res => {
        let data = res.data
        this.$store.state.shareCode = data.InvitationCode
      })

      wallet().then(res => {
        let data = res.data.Items[0]
        if (data) {
          this.userMoney = data.Balance.toFixed(2)
        }
      })

      sellerStatistics().then(res => {
        this.statisticsData = res.data
      })
    },
    copyData() {
      const clipboard = new Clipboard('.copy-icon', {text: () => this.shareLink});
      this.$toast(this.$t('fu-zhi-cheng-gong'))
    }
  }
}
</script>